// ==============================
// Override Variables
// 覆盖变量
// ==============================
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Roboto:wght@300;900&family=Yanone+Kaffeesatz:wght@200;400&display=swap');

h1 {
    font-family: 'Fredericka the Great', cursive;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 3rem;
    color: #696969;
    line-height: 1.5rem;
    text-transform: uppercase;
    margin: 0;
  }
  /* Tablet font size and line height (approximately 768px to 991px) */
@media (max-width: 991px) {
    h1 {
      font-size: 2.5rem; /* Adjusted for tablets */
      line-height: 1.3rem; /* Adjusted for tablets */
    }
  }
  
  /* Mobile font size and line height (approximately up to 767px) */
  @media (max-width: 767px) {
    h1 {
      font-size: 2rem; /* Adjusted for mobiles */
      line-height: 1.2rem; /* Adjusted for mobiles */
    }
  }


h2 {
    font-family: 'Fredericka the Great', cursive;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 3rem;
    color: #696969;
    line-height: 54px;

}

body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.8rem;
}

blockquote {
    display: block;
    border-left: 0.5rem solid #e53310fd;
    background-color: none;
    padding: 0.25rem 0.75rem;
    margin: 1rem 0;
}

.single .post-meta .post-publish{
    display:none;
}

.single .post-meta a, .single .post-meta a::before, .single .post-meta a::after  {
    color:#696969;
    line-height: 0;
}

span.post-category {
    margin-top:4%;
    text-transform:uppercase;
    letter-spacing: 3px;
    font-size: .7em;

 }
 
 h1.single-title.animate__animated.animate__flipInX {
     margin-top: 0%;
 }

 

 a.archive-item-link {
    text-transform: uppercase;
 }

a.next {
    text-align: right;
}

/*This is to remove linkable hashtags before h2 */

a.header-mark::before {
    display:none;
}

/* The following is to avoid words breaking; whole words pass to the next line */
* {
    word-wrap: normal;
  }