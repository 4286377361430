// ==============================
// Custom style
// 自定义样式
// ==============================
blockquote {
    background-color: white;
    font-size:.9rem;
}

a {
    font-weight: bold;
}

a.menu-item {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: small;
    $global-font-color: #161209 !default;
    $global-font-color-dark: #a9a9b3 !default;
}

figcaption {
    text-align: right;
    font-size: .6rem;
    font-weight:lighter;
    margin: top 0;
    padding-top: 0%;
}

/* This is for the subscription in the footer */

div#mc_embed_signup_scroll {
    text-align: left;
    color:white;
}
div#mc_embed_signup_scroll h2 {
    font-family: Roboto;
    font-size: 1rem;
    color:white;
    line-height:1.5rem;
}

div.indicates-required {
    text-align: right;
    font-size: small;
    color:white;
}

div#mc_embed_shell {
    background-color: black;
    padding: 0;
    margin-top: 1rem;
    width:100%;
}

div.optionalParent {
  background-color: black;
  padding-top:0;
  margin-top:0;
  width:100%;
}

input#mc-embedded-subscribe.button {
  color:white;
  font-weight:bold;
  background-color:#DE2A24;
}

h2#sub {
    color:#DD2F31;
    margin:0%;
}

div.tags {
    text-align:right;
    color:gray;
    padding-right:10px;
}

div.tags.a {
    color:gray;
}

a.markdown-btn  {
    color: white;
    background-color: greenyellow;
    border-radius: 8px;
    text-align: center;
    padding: 5px 1em 5px 1em;
    text-decoration: none;
    border: 1px;
    cursor: pointer;
}

div.button {
    text-align:center;
}
.single .content a {
    color:#DD2F31;
 }
a.next {
    text-align:right;
    margin-top:1rem;
    margin-bottom:1rem;
    color:gray;
}

a.prev {
    color:gray;
}

/* Assuming the container for related posts */
div.related-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
  gap: 20px; /* Adjust the gap between columns */

  /* Responsive adjustments */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Single column on even smaller screens */
  }
}

/* Style for each individual related post item */
div.related-post {
  border: 1px solid #ddd; /* Add a border for each post */
  padding: 15px; /* Add padding for better appearance */
}

/* Style for the featured image within each post */
div.related-post img {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  height: auto; /* Maintain the aspect ratio of the image */
  display: block; /* Remove extra space beneath the image */
  margin-bottom: 10px; /* Add some space below the image */
}

/* Style for the title within each post */
div.related-post h3 {
  font-size: 1.2em; /* Adjust the font size as needed */
  margin-bottom: 10px; /* Add some space below the title */
}

/* Style for the description within each post */
div.related-post p {
  margin: 0; /* Remove default margin */
}

h5 {
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .book-ad {
    border: 1px solid #000; /* 1px black border */
    padding: 10px; /* Add padding for better appearance */
    background-color: #F5F5F5;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .book-ad h6,
  .book-ad h2,
  .book-ad p {
    margin: 10px; /* Remove default margin */
  }
  
  .book-ad img {
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Remove extra space beneath the image */
    margin-bottom: 30px; /* Add some space below the image */
    float: right;
    }

  .book-ad h6 {
    text-align:right;
    font-weight:100;
  }

  .book-ad h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 4rem; /* Desktop font size */
    line-height:3.5rem;
  }
  
  /* Tablet font size (approximately 768px to 991px) */
  @media (max-width: 991px) {
    .book-ad h2 {
      font-size: 3rem;
      line-height: 2.8rem;
    }
  }
  
  /* Mobile font size (approximately up to 767px) */
  @media (max-width: 767px) {
    .book-ad h2 {
      font-size: 2rem;
      line-height:1.9rem;
    }
  }

  button {
    background-color: orange;
    color: black;
    text-align: center;
    font-weight: bold;
    margin: auto;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    border: none;
    padding: 12px;
  }

.book-ad p {
    margin: 20px;
    padding-top: 20px;
    font-size: .9rem;
    line-height: 160%;
}

/* Apply styles to the form container */
#web3forms {
  max-width: 800px;
  margin: 0 auto;
}

/* Apply styles to form elements */
#web3forms form {
  display: grid;
  gap: 15px;
}

#web3forms input,
#web3forms textarea,
#web3forms button {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

#web3forms button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  #web3forms {
    max-width: 100%;
  }
}

div.page.single.special h1 {
  margin-top: 4rem;
}

div.home-profile img{
  width: 100%;
}

figure.author img {
  padding-bottom: 2rem;

}

#we-make-your-spanish-more {
  text-align:center;
  font-size: 4rem;
}

div#id-1 {
  font-size: 1.1rem;
}

/* static/css/book-section.css */

.book-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.book-column {
  flex: 1 0 200px;
  text-align: center;
  margin: 10px;
}

.book-column img {
  max-width: 100%;
  height: auto;
}

button {
  background-color: #007BFF;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
}
button.d2digital {
  background-color:#FF5733;
}

div.book-section {
  background-color: #E8E8E8;
  padding-left:0;
  padding-right:0;

}

div.book-column h3 {
  text-transform: uppercase;
  font-size:1.4rem;
  margin-bottom:0;
}

div.single {
  margin-top:0px;
}

div#content.content {
  max-width:800px;
  width:100%;
}
/*
article.page.single {
  max-width:800px;
  width:60%;
}*/

/* Base rule for single pages (not the home page) on desktop */
article.page.single:not(.home) {
  max-width: 800px;
  width: 60%;
}

/* Tablet width (approximately 768px to 991px) */
@media (max-width: 991px) {
  article.page.single:not(.home) {
    width: 70%; /* Increased width for tablets */
  }
}

/* Mobile width (approximately up to 767px) */
@media (max-width: 767px) {
  article.page.single:not(.home) {
    width: 80%; /* Further increased width for mobiles */
  }
}

div.page.archive {
  max-width: 600px;
  width: 80%;
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  div.page.archive {
      width: 100%; /* Adjust as needed for full width on smaller screens */
  }

  h2.archive-item-title {
    font-size:.4rem;
    overflow-wrap: break-word;
  }
}

div.page.single.special {
  max-width: 1200px;
  width: 70%;
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  div.page.single.special {
      width: 100%; /* Adjust as needed for full width on smaller screens */
  }
}

img.chili {
  max-width:80%;
  object-fit: cover;

}

div.chili-lead {
  text-align:center;
  margin-top: -15%;
  margin-bottom: 3%;
}

div.page.home {
  width:100%;
}

/* static/css/pig-section.css */

.pig {
  background-color: #3C4142 ;
  color: white;
  padding: 1% 0;
}

.pig-pen {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.home-headers {
  font-size: 1.3rem;
  text-transform: uppercase;
  color:white;
  font-family:Roboto;
  font-weight:700;
  letter-spacing: 5px;
}

.pig-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.pig-image {
  max-width: 100%;
  margin-right: 10%;
}

.pig-text {
  flex: 1;
  text-align: left; /* Align text to the left */
}

.pig-text h3 {
  font-size: 3rem;
  font-family:Yanone Kaffeesatz;
  color:#EEC5CD;
  margin-bottom:1%;
}

.pig-text p {
  font-size: 1rem;
  line-height: 1.9;
  font-weight:100;
  letter-spacing: 2px;
  color: white;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .pig-content {
      flex-direction: column; /* Stack items vertically on small screens */
      text-align: center; /* Center text on small screens */
  }

  .pig-image {
      margin-bottom: 10px; /* Reduce space between image and text on small screens */
  }
  .pig-text p {
    margin-left: 25px;
    margin-right: 25px;
    font-size: 1rem;
  }

  .pig-text h3 {
    font-size:2rem;
    margin-left: 25px;

  }
}

/* static/css/toilet-section.css */

.toilet-section {
  background-color: #5FAACC;
  color: white;
  padding: 15px 0;
}

.john {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.toilet-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.toilet-image {
  max-width: 100%;
  margin-left: 10%;
  margin-bottom: 20px;
}

.toilet-text {
  flex: 1;
  text-align: left;
  margin-top:0;
  margin-left:10%;
}

.toilet-text h3 {
  font-size: 3rem;
  font-family:Yanone Kaffeesatz;
  color:#EEC5CD;
  margin-bottom:1%;
}

.toilet-text p {
  font-size: 1rem;
  line-height: 1.9;
  font-weight:100;
  letter-spacing: 2px;
}

.term-country-layout {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.term-country-layout > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terms {
  color: #EEC5CD;
  font-family: Yanone Kaffeesatz;
  font-weight: bold;
  font-size: 2.5rem;
  letter-spacing: 2px;
}

.countries {
  color: white;
  font-family: Roboto;
  letter-spacing: 3px;
  font-weight:bold;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .toilet-content {
      flex-direction: column;
      text-align: center;
  }

  .toilet-image {
      margin-left: 0;
      margin-bottom: 1px;
  }

  .term-country-layout {
      flex-direction: column;
  }
  .toilet-text {
    margin-left: 25px;
    margin-right: 25 px;
  }
}

/*The following is for the category pages*/

span.archive-item-date {
  display:none;
}

.archive-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid gray;
  margin-bottom: 20px; /* Adjust as needed for spacing between articles */
  padding: 15px; /* Adjust as needed for inner spacing within articles */
  background-color: #F7F7F7;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle drop shadow */
}


.featured-image {
  max-width: 100%;
  margin-bottom: 0; /* Adjust as needed for spacing between image and content */
}

div.archive-item-content {
  text-align:left;
  border:#3C4142;
  border-radius: 1px;
}

h2.archive-item-title {
  margin-bottom:.5rem;
  margin-top:.4rem;
  font-size:1.5rem;
  overflow-wrap: hidden;
  white-space: normal;
  max-width:100%;
  line-height:2rem;
}

p.archive-item-description {
  margin-top: -.5rem;
  margin-bottom:.1rem;
}

.archive-item-title,
.archive-item-description,
.archive-item-date {
    margin: 0; /* Remove default margins for inner elements */
}

h2.single-title.animate__animated_animate__pulse_animate__faster {
  font-size: 4rem;

}

/* Below is the CSS for the blonde on front page */

.home-headers-light {
  font-size: 1.3rem;
  text-transform: uppercase;
  color:black;
  font-family:Roboto;
  font-weight:700;
  letter-spacing: 5px;
  text-align:center;
}

.blonde-text h3 {
  font-size: 3rem;
  font-family:Yanone Kaffeesatz;
  color:#0060F8;
  margin-bottom:0;
  text-align:left;
  line-height: 3rem;
}

.blonde-container {
  background-color: white;
  padding: 20px;
  text-align: center;
}

.home-headers-light {
  width: 100%;
  margin-bottom: 20px;
}

.content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.image-container {
  flex: 1;
  margin-right: 20px; /* Add some spacing between image and text */
}

.image-container img {
  width: 100%;
  border-radius: 8px;
  margin-bottom:-26px;
}

.blonde-text {
  flex: 2;
  margin-bottom:2rem;
}

.term-country-layout1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top:1.5rem;
}

.column {
  width: 100%;
  max-width: 300px; /* Adjust the max-width as needed */
  margin-bottom: 20px; /* Add some spacing between columns */
}

.terms1,
.countries1 {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  display: block;
  margin-bottom: 10px;
}

/* For larger screens, show the terms/countries in two columns */
@media screen and (min-width: 769px) {
  .term-country-layout1 {
    justify-content: space-between;
  }

  .column {
    width: 48%;
    margin-bottom: 0; /* Remove spacing between columns */
  }
}


.terms1 {
  font-size: 3rem;
  font-family:Yanone Kaffeesatz;
  font-weight:bold;
  color:#0060F8;
  margin-bottom:-5%;
}

.countries1 {
  color: black;
  font-family: Roboto;
  letter-spacing: 3px;
  font-weight:bold;
  margin-top:0; 
}

div.blonde-text p {
  font-size: 1rem;
  line-height: 1.9;
  font-weight:100;
  letter-spacing: 2px;
  text-align:left;
  padding-bottom:2rem;
  margin-top:-1rem;
}

/* The following are elements for the toucan div on homepage */

/* Your CSS file (e.g., static/css/styles.css) */

.insider-spanish {
  background-color: #07515D;
  color: #D7DD49;
  padding: 20px;
  padding-right:0;
  padding-bottom:0;
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.text-content {
  flex: 1;
}

.insider-header {
  font-size: 3rem;
  font-family:Yanone Kaffeesatz;
  color:#D7DD49;
  margin-bottom:1%;
  text-align:left;
  line-height: 3rem;
}

.toucan-text {
  font-size: 1rem;
  line-height: 1.9;
  font-weight:100;
  letter-spacing: 2px;
  color:white;
}

.toucan-image {
  flex: 1;
  text-align: right;
  margin-top:-5%;
  margin-bottom:-10px;
}

.toucan-image img {
  max-width: 100%;
  height: auto;
  margin-bottom:0;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .content-wrapper {
    flex-direction: column-reverse;
  }

  .toucan-image {
    text-align: center;
    margin-top: 20px; /* Add some spacing between text and image on smaller screens */
  }
}

/* This is for latest posts section of front page */

.latest-posts {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Adjusted to left align items */
  background-color: #E2E2E2;
  padding-left:15px;
  padding-right:15px;
}

.latest-posts-header {
  font-size: 1rem;
  color: #333; /* Adjust the color as needed */
  margin-bottom: 0;
  padding-left: 15px;
}

.post-items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Adjusted to left align items */
  width: 100%; /* Ensure it takes full width */
}

.post-item {
  display: flex;
  flex-direction: column; /* Change to column */
  align-items: flex-start; /* Adjusted to left align items */
  width: 80%; /* Ensure it takes full width */
  max-width: 600px; /* Adjust max width as needed */
  margin-bottom: 20px;
  padding: 20px;
}

.post-image {
  margin-bottom: 10px; /* Add spacing between image and content */
}

.post-image img {
  width: 100%;
  height: auto;
  border-radius: 0; /* Removed border-radius */
  margin-bottom: 0;
}

.post-content {
  text-align: left; /* Left align text */
  width: 100%; /* Ensure content takes full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.post-title {
  font-size: 20px;
  margin-bottom: 5px;
  width: 100%; /* Ensure title takes full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  text-transform: uppercase;
  margin-top: 0;

}

.post-description {
  font-size: .9rem;
  width: 100%; /* Ensure description takes full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-top: 0;
  font-style: italic;
}

/* Responsive adjustments */
@media screen and (min-width: 769px) {
  .post-items-container {
    flex-direction: row;
  }

  .post-item {
    margin-right: 20px; /* Adjust the right margin to create space between columns */
  }

  .post-item:last-child {
    margin-right: 0; /* Remove the right margin from the last column */
  }
}

/* The following is the custom footer */

.footer2 {
  background-color: #000;
  padding: 20px;
  margin-top: 0;
}

.footer-logo img {
  display: block;
  margin: 0 auto;
  max-width: 20%; /* Adjust to your preference */
  object-fit: contain;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1;
  max-width: 40%; /* Adjust widths as needed */
  margin: 0 15px;
}


.centered-image {
  display: block;
  margin: 0 auto;
  max-width: 40%; /* Adjust to your preference */
  object-fit: contain;
}

img.sombrero {
  margin: auto;
  display:block;
  margin-bottom:0;
}

.mission {
  font-size: 1rem;
  margin-bottom: 15px;
  color: white;
  font-weight: 700;
  width:100%;
}

.signup-form {
  text-align: left;
}

.form-group {
  position: relative;
}

.required-text {
  color: red;
}

.privacy-policy {
  margin-top: 0rem;
  font-size: .7rem;
  color: white;
  margin-left: 17px;
}

.copyright {
  color: #fff;
  margin-top: 0;
  font-size: 0.7rem;
  line-height: .9rem;
  margin-left: 17px;
}

.footer-heads {
  color: #DE2A24;
  text-decoration: underline;
}

.footer-heads1 {
  text-align: center;
  color: #DE2A24;
  text-decoration: underline;
  margin-bottom:0;
}

.white-text {
  color: #fff;
}

.site-links {
  list-style: none;
  padding: 0;
}

.site-links a {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}

.site-links a:hover {
  text-decoration: underline;
}

.footer-column img {
  margin-top: 10%;
}

.affiliate {
  color: white;
  text-align: center;
  font-size: 0.7rem;
  max-width:60%;
  margin: 0 auto;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .footer-column {
    max-width: 100%; /* Full width on smaller screens */
    margin-bottom: 15px;
  }
}

/* Below is for the social icons in the footer */

.social-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  max-width:50%;
  margin-left: auto;
  margin-right: auto;
}

.social-icons a {
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-top: 0;
}


.social-icons a:hover {
  color: #ff0000; /* Change to your desired hover color */
}

/* This for the contact form on the contact page */
/* Adjust the styles based on your design preferences */

.web3 {
  text-align: left;
  margin-top: 3rem;
}

.web3 form {
  max-width: 75%;
  margin: 0 auto;
  padding: 30px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.web3 input, textarea, button {
  width: 200px;
  margin-bottom: 0px;
  margin-top:0;
  padding-top: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.web3 button {
  background-color: #DE2A24;
  color: white;
  cursor: pointer;
}

.web3 button:hover {
  background-color: #5E5E5E;
}

.web3 .h-captcha {
  margin-bottom: 30px;
  margin-top:30px;
}

.form-headers {
  font-size: 1rem;
  color: #333; /* Adjust the color as needed */
  margin-bottom: 0;
  padding-bottom:0;
  color:#DE2A24;
  letter-spacing: 2px;
}

post-meta-line time {
  display:none;
}
